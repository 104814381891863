import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  content,
  name,
  full_slug,
  title,
  description,
  og_title,
  og_description,
  og_type,
  og_url,
  og_image,
  og_image_width,
  og_image_height,
  article_published_time,
  article_modified_time,
  og_locale,
  twitter_title,
  twitter_description,
  twitter_image,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleSeparator
            description
            author
            twitter_creator
            siteUrl
            color
          }
        }
      }
    `
  )

  const bestMatch = (array) => {
    return array.find((e) => e !== undefined && e !== "")
  }

  // Einmal "c" vorbereiten, um unten nicht stätig prüfen zu müssen, ob content übergeben wurde
  const c =
    content && Object.keys(content).length > 0 ? content : { metadata: {} }
  const m = c.metadata ? c.metadata : {}
  const schemaMarkup = c.schema_markup ? c.schema_markup : null

  const og_site_name = site.siteMetadata.title
  const twitter_creator = site.siteMetadata.twitter_creator

  const IMAGE_SIZE = {
    facebook: {
      width: "1200",
      height: "630",
    },
    twitter: {
      width: "1024",
      height: "512",
    },
  }

  const resizeImage = (image, sizes) => {
    const imageService = "https://img2.storyblok.com"
    const fileName = image?.replace("https://a.storyblok.com/", "")
    const imageSize = `${sizes.width}x${sizes.height}`
    const imageUrl = `${imageService}/${imageSize}/smart/${fileName}`
    return imageUrl
  }

  let data = []

  // SEO Variablen (einige sind optional)
  data.push({
    name: `title`,
    content: `${bestMatch([m.title, title, c.headline, c.title, name])}${
      site.siteMetadata.titleSeparator
    }${site.siteMetadata.title}`,
  })
  data.push({
    name: `description`,
    content: bestMatch([m.description, description, c.subheadline, c.teaser]),
  })
  data.push({
    property: `msapplication-TileColor`,
    content: site.siteMetadata.color,
  })
  data.push({
    property: `og:site_name`,
    content: og_site_name,
  })
  data.push({
    property: `og:title`,
    content: bestMatch([
      m.og_title,
      og_title,
      m.title,
      c.headline,
      c.title,
      title,
      name,
    ]),
  })
  data.push({
    property: `og:description`,
    content: bestMatch([
      m.og_description,
      og_description,
      m.description,
      description,
      c.subheadline,
      c.teaser,
      site.siteMetadata.description,
    ]),
  })
  data.push({
    property: `og:type`,
    content: og_type ? og_type : "website",
  })
  data.push({
    property: `og:url`,
    content: bestMatch([og_url, full_slug]),
  })
  data.push({
    property: `og:image`,
    content: resizeImage(
      bestMatch([og_image, m.og_image, c.hero_image]),
      IMAGE_SIZE.facebook
    ),
  })
  data.push({
    property: `og:image:width`,
    content: og_image_width,
  })
  data.push({
    property: `og:image:height`,
    content: og_image_height,
  })
  data.push({
    property: `article:published_time`,
    content: article_published_time,
  })
  data.push({
    property: `article:modified_time`,
    content: article_modified_time,
  })
  data.push({
    property: `og:locale`,
    content: bestMatch([og_locale, c.locale]),
  })
  data.push({
    name: `twitter:card`,
    content: `summary_large_image`,
  })
  data.push({
    name: `twitter:title`,
    content: bestMatch([
      m.twitter_title,
      m.og_title,
      twitter_title,
      og_title,
      m.title,
      c.headline,
      c.title,
      title,
      name,
    ]),
  })
  data.push({
    name: `twitter:description`,
    content: bestMatch([
      m.twitter_description,
      m.og_description,
      twitter_description,
      og_description,
      m.description,
      description,
      c.subheadline,
      c.teaser,
    ]),
  })
  data.push({
    name: `twitter:site`,
    content: twitter_creator,
  })
  data.push({
    name: `twitter:creator`,
    content: twitter_creator,
  })
  data.push({
    name: `twitter:image`,
    content: resizeImage(
      bestMatch([
        twitter_image,
        m.twitter_image,
        m.og_image,
        og_image,
        c.hero_image,
      ]),
      IMAGE_SIZE.twitter
    ),
  })

  data.push({
    name: `viewport`,
    content:
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0",
  })

  data = data.filter((x) => x.content && x.content !== undefined)

  return (
    <Helmet
      title={`${bestMatch([m.title, title, c.title, name])} ${
        content && content.component === "start_page"
          ? ""
          : `${site.siteMetadata.titleSeparator}${site.siteMetadata.title}`
      }`}
      meta={data.concat()}
    >
      {schemaMarkup && schemaMarkup !== "" && (
        <script type="application/ld+json">{schemaMarkup}</script>
      )}
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

SEO.defaultProps = {
  og_image_width: "1200",
  og_image_height: "630",
  og_locale: "de",
}

export default SEO
