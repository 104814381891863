import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import HeadlineBlock from "../components/blocks/HeadlineBlock"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

import * as moment from "moment"
import Masonry from "react-masonry-css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons"

const Magazin = ({ data }) => {
  const { pageData, entries } = data
  const pageContent = JSON.parse(pageData.content)
  const news = entries.nodes
  const NEWS_LIMIT = 12

  const [selectedCategory, setSelectedCategory] = useState("all")
  const [allNews, setAllNews] = useState([...news])
  const [newsList, setNewsList] = useState([...news.slice(0, NEWS_LIMIT)])
  const [hasMore, setHasMore] = useState(newsList.length > NEWS_LIMIT)
  const [loadMore, setLoadMore] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const containerRef = useRef(null)

  let tagList = []
  news.forEach((node) => {
    node.tag_list.forEach((tag) => tagList.push(tag))
  })
  tagList.sort()
  const uniqueCategories = new Set(tagList)
  const categories = [...uniqueCategories]

  const filterNews = (category, news) => {
    if (category !== "all") {
      const filteredNews = news.filter((item) => {
        if (item.tag_list.includes(category)) {
          return item
        }
        return null
      })
      setAllNews([...filteredNews])
      setNewsList([...filteredNews.slice(0, NEWS_LIMIT)])
    } else {
      setAllNews([...news])
      setNewsList([...news.slice(0, NEWS_LIMIT)])
    }
  }

  const handleChange = (e) => {
    setSelectedCategory(e.target.value)
    filterNews(e.target.value, news)
  }

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = newsList.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + NEWS_LIMIT)
        : []
      setNewsList([...newsList, ...nextResults])
      setLoadMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, hasMore, newsList])

  useEffect(() => {
    const isMore = newsList.length < allNews.length
    setHasMore(isMore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsList])

  const breakpoints = {
    default: 3,
    1024: 2,
    768: 1,
  }

  return (
    <div>
      <Layout metadata={pageContent.metadata}>
        <SEO content={pageContent} />
        {/* <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs> */}
        <HeadlineBlock
          block={{
            headline_level: "h1",
            kicker: pageContent.kicker,
            headline: pageContent.headline,
            subheadline: pageContent.subheadline,
          }}
        />
        <BlockWrapper block={{ block_width: "" }}>
          <select
            className="px-4 py-2 border border-secondary text-secondary"
            value={selectedCategory}
            onChange={handleChange}
          >
            <option value={"all"}>Alle Kategorien</option>
            {categories.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </select>
          <div className="mt-8 mx-grid" ref={containerRef}>
            <Masonry
              breakpointCols={breakpoints}
              className="masonry-grid"
              columnClassName="masonry-grid_column"
            >
              {newsList.map((item, index) => {
                const content = JSON.parse(item.content)
                //console.log(item.tag_list, selectedCategory)
                if (
                  (item.tag_list.includes(selectedCategory) ||
                    selectedCategory === "all") &&
                  content.headline?.length > 0
                ) {
                  return (
                    <div className="mb-8" key={index}>
                      <Link
                        className={`block text-white transition-all duration-200 ease-in transform  ${
                          content.card_style
                            ? `bg-${content.card_style}`
                            : `bg-blue`
                        } hover:scale-105 hover:shadow-lg`}
                        link={`/${item.full_slug}`}
                      >
                        <div className="p-8">
                          {content.kicker?.length > 0 && (
                            <span
                              className={`inline-block px-3 py-1 font-bold uppercase bg-white ${
                                content.card_style
                                  ? `text-${content.card_style}`
                                  : `text-blue`
                              } lg:text-lg font-display`}
                            >
                              {content.kicker}
                            </span>
                          )}
                          {content.headline?.length > 0 && (
                            <span className="block mt-2 text-2xl font-black hyphens-auto lg:text-3xl font-display">
                              {content.headline}
                            </span>
                          )}
                          {content.subheadline?.length > 0 && (
                            <span className="block mt-4 font-bold">
                              {content.subheadline}
                            </span>
                          )}
                          {item.first_published_at !== null && (
                            <span className="inline-block mt-2 text-sm">
                              <FontAwesomeIcon
                                icon={faLongArrowAltRight}
                                size="2x"
                                className="mt-1 mr-2 -mb-1"
                              />

                              <span className="inline-block text-base font-bold">
                                {moment(item.first_published_at).format(
                                  "DD.MM.YYYY"
                                )}
                              </span>
                            </span>
                          )}
                        </div>
                      </Link>
                    </div>
                  )
                }
                return null
              })}
            </Masonry>
          </div>
          {hasMore ? (
            <div className="text-center mt-9">
              <button className="btn btn-primary" onClick={handleLoadMore}>
                Mehr anzeigen
              </button>
            </div>
          ) : null}
          <BodyBlocks body={pageContent.body} />
        </BlockWrapper>
      </Layout>
    </div>
  )
}

export const data = graphql`
  {
    pageData: storyblokEntry(slug: { eq: "magazin" }) {
      name
      slug
      content
      full_slug
    }
    entries: allStoryblokEntry(
      filter: { field_component: { eq: "news_page" } }
      sort: { fields: first_published_at, order: DESC }
    ) {
      nodes {
        full_slug
        name
        content
        tag_list
        field_component
        first_published_at
      }
    }
  }
`

export default Magazin
